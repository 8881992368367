import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { companyLogo } from "../../store/slices/auth-slice";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const Logo = ({ imageUrl, logoClass }) => {
  const { companyLogoImage } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const imageUrlLogo = `data:image/png;base64,${companyLogoImage?.imageBase64}`;

  return (
    <div className={clsx(logoClass)}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        <img  
          src={imageUrlLogo}
          alt="Base64 Image"
          height="50px"
        />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;
