import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'antd';
import moment from 'moment';
// import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import AppConsts from "../../appconst";
import { MasterType } from "../../constants/MasterType";
import { fulfillmentStatus } from "../../store/slices/fullfilment-slice";
// import Navbar from '../../components/navbar';
// import Header from '../../components/header';
// import Footer from '../../components/footer';
import { EditOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";

const  OrderFulfillmentStatus = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { fulfillmentList } = useSelector((state) => state.fulfillment);

    let { id } = useParams();

    const formatDate = (dateString) => {
        const formattedDate = moment(dateString).format('MMM D [at] h:mm A');
        return formattedDate;
    };

    const columns = [
        { title: 'Product Name', dataIndex: 'productName'},
        { title: 'Quantity', dataIndex: 'quantity'},
        {
            title: 'Date',
            dataIndex: 'orderDate',
            render: (orderDate) => formatDate(orderDate),
        },
        {
            title: 'Forward Status',
            dataIndex: 'forwardStatus',
            render: (forwardStatus) => {
                let color = '';
                switch (forwardStatus) {
                    case 'Pending':
                        color = '#ffcc00'; // Yellow
                        break;
                    case 'Confirmed':
                        color = '#52c41a'; // Green
                        break;
                    case 'Rejected':
                        color = '#f5222d'; // Red
                        break;
                    case 'Ready To Pickup':
                        color = '#2db7f5'; // Blue
                        break;
                    case 'Intransit':
                        color = '#1890ff'; // Light blue
                        break;
                    case 'Delivered':
                        color = '#87d068'; // Light green
                        break;
                    default:
                        color = '#000000'; // Black
                }
                return <span style={{ color, fontWeight: 'bold' }}>{forwardStatus}</span>;
            },
        },
        {
            title: 'Reverse Status',
            dataIndex: 'reversalStatus',
            render: (reversalStatus) => {
                let color = '';
                switch (reversalStatus) {
                    case 'Pending':
                        color = '#ffcc00'; // Yellow
                        break;
                    case 'Return Request':
                        color = '#722ed1'; // Purple
                        break;
                    case 'Return Approve':
                        color = '#12b886'; // Green
                        break;
                    case 'Return Reject':
                        color = '#eb2f96'; // Pink
                        break;
                    case 'Intransit':
                        color = '#1890ff'; // Blue
                        break;
                    case 'Return Received':
                        color = '#faad14'; // Gold
                        break;
                    case 'Return Pending':
                        color = '#722ed1'; // Purple
                        break;
                    case 'Ready To Return':
                        color = '#1890ff'; // Blue
                        break;
                    case 'Returned':
                        color = '#52c41a'; // Lime
                        break;
                    case 'Refund Request':
                        color = '#fa8c16'; // Orange
                        break;
                    case 'Refund Approved':
                        color = '#13c2c2'; // Cyan
                        break;
                    case 'Refund Rejected':
                        color = '#eb2f96'; // Pink
                        break;
                    case 'Refunded':
                        color = '#52c41a'; // Lime
                        break;
                    case 'Product Return Refund Request':
                        color = '#ff7a45'; // Volcano
                        break;
                    case 'Product Return Refund Approve':
                        color = '#722ed1'; // Purple
                        break;
                    case 'Product Return Refund Reject':
                        color = '#722ed1'; // Purple
                        break;
                    case 'Without Product Return Refund Request':
                        color = '#fa8c16'; // Orange
                        break;
                    case 'Without Product Return Refund Approve':
                        color = '#faad14'; // Gold
                        break;
                    case 'Without Product Return Refund Reject':
                        color = '#eb2f96'; // Pink
                        break;
                    case 'Refund Product Pending Return':
                        color = '#ff4d4f'; // Red
                        break;
                    case 'Refund Product Ready To Return':
                        color = '#52c41a'; // Lime
                        break;
                    case 'Refund Product Return Intransit':
                        color = '#1890ff'; // Blue
                        break;
                    case 'Refund Product Return Received':
                        color = '#13c2c2'; // Cyan
                        break;
                    default:
                        color = '#000000'; // Black
                }
                return <span style={{ color, fontWeight: 'bold' }}>{reversalStatus}</span>;
            },
        }
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     render: (orderDate) => (
        //         <EditOutlined style={{ color: '#1890ff', fontSize: '16px', pointer: 'cursor' }} />
        //     ),
        // },
    ];

    useEffect(() => {
        dispatch(fulfillmentStatus(id));
    }, [])

    return (
        <>
            {/* <Header /> */}
            {/* <Navbar /> */}
            <Fragment>
                {/* <SEO
        titleTemplate="Order History"
        description="Order history page of ecommerce react minimalist eCommerce template."
      /> */}

                <LayoutOne headerTop="visible">
                <div className="cart-main-area pt-50 pb-50 ">
                    <div className="container">
                        {fulfillmentList && fulfillmentList.length >= 1 ? (
                            <Fragment>
                                <div className="mb-4 ">
                                    <h3 className="heading-bg-title">Fulfillment Status</h3>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-content table-responsive">
                                            <Table className="auth-ant-table" columns={columns} dataSource={fulfillmentList} rowKey="id"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="item-empty-area text-center">
                                        <div className="item-empty-area__icon mb-30">
                                            <i className="pe-7s-cart"></i>
                                        </div>
                                        <div className="item-empty-area__text">
                                            No status found <br />{" "}
                                            <div className="text-center">
                                                <Link
                                                    to={process.env.PUBLIC_URL + "/"}
                                                    >
                                                        Shop now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                </LayoutOne>
            </Fragment>
            {/* <Footer /> */}
        </>
    );
};

export default OrderFulfillmentStatus;