import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Table, Badge } from 'antd';
import moment from 'moment';
import { useEffect } from "react";
import AppConsts from "../../appconst";
import LayoutOne from "../../layouts/LayoutOne";
import { fetchOrderDetails } from "../../store/slices/checkout-slice";
// import Navbar from '../../components/navbar';
// import Header from '../../components/header';
// import Footer from '../../components/footer';

const OrderDetails = () => {

    const dispatch = useDispatch();
    let { id } = useParams();

    const { orderDetails } = useSelector((state) => state.checkout);
    const { masterData } = useSelector((state) => state.common);

    const columns = [
        { title: 'Product', dataIndex: 'productName' },
        { title: 'Price', dataIndex: 'salesRate' },
        { title: 'Quantity', dataIndex: 'quantity' },
        { title: 'Total', dataIndex: 'orderTotal', render: (text, record) => <span>{record.salesRate * record.quantity}</span> },
    ];

    useEffect(() => {
        dispatch(fetchOrderDetails(id));
    }, [])

    return (
        <>
            {/* <Header /> */}
            {/* <Navbar /> */}
            <Fragment>
            <LayoutOne headerTop="visible">
                <div className="cart-main-area pt-90 pb-100">
                    <div className="container">
                        <Fragment>
                            <div className="row" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <div className="col-12">
                                    <div className="table-content table-responsive">
                                        <Link to={process.env.PUBLIC_URL + "/order-history"} style={{ textDecoration: 'none', marginBottom: '10px' }}>Back to orders</Link>
                                        <Table className="auth-ant-table" style={{ marginTop: '10px' }} columns={columns} dataSource={orderDetails?.productDetails} rowKey="id" pagination={false}
                                            // scroll={{ y: 400 }} style={{ marginBottom: 10 }} 
                                            // footer={()=>footerRow}
                                            summary={() => {
                                                return (
                                                    <>
                                                        <tr className="footer-td">
                                                            <td colSpan={3}>Sub Total</td>
                                                            <td>
                                                                {orderDetails?.orderSubTotal}
                                                            </td>
                                                        </tr>
                                                        <tr className="footer-td">
                                                            <td colSpan={3}>Discount</td>
                                                            <td>
                                                                {orderDetails?.discount}
                                                            </td>
                                                        </tr>
                                                        <tr className="footer-td">
                                                            <td colSpan={3}>Order Total</td>
                                                            <td style={{ fontWeight: 'bold' }}>
                                                                {orderDetails?.orderTotal}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <div className="col-lg-4">
                                    <h3 className="cart-page-title" style={{ margin: '0px' }}>Order No: {orderDetails?.orderNo}</h3>
                                    <p className="cart-page-title" style={{ marginBottom: '3px' }}>Order Status: <Badge color="peachpuff" style={{ color: 'Black' }} count={masterData.find(m => m.value === orderDetails?.paymentStatus)?.name} ></Badge></p>
                                    <h7 className="cart-page-title">Order Date: Placed on {moment(orderDetails?.orderDate).format(AppConsts.DATE_FORMAT_MMMM_D_COMMA_YYYY_AT_H_MM_A)}</h7>
                                </div>
                                <div className="col-lg-4">
                                    <div className="your-order-area">
                                        <div className="your-order-wrap gray-bg-4">
                                            <div className="your-order-product-info">
                                                <ul style={{ listStyleType: "none" }}>
                                                    <b>SHIPPING ADDRESS</b>
                                                    <li>{orderDetails?.shippingAddress.name}</li>
                                                    <li>{orderDetails?.shippingAddress.addressLine} {orderDetails?.shippingAddress.area}</li>
                                                    <li>{orderDetails?.shippingAddress.landmark}</li>
                                                    <li>{orderDetails?.shippingAddress.pincode} - {orderDetails?.shippingAddress.city}</li>
                                                    <li>{orderDetails?.shippingAddress.state}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="your-order-area">
                                        <div className="your-order-wrap gray-bg-4">
                                            <div className="your-order-product-info">
                                                <ul style={{ listStyleType: "none" }}>
                                                    <b>BILLING ADDRESS</b>
                                                    <li>{orderDetails?.billingAddress.name}</li>
                                                    <li>{orderDetails?.billingAddress.addressLine} {orderDetails?.billingAddress.area}</li>
                                                    <li>{orderDetails?.billingAddress.landmark}</li>
                                                    <li>{orderDetails?.billingAddress.pincode} - {orderDetails?.billingAddress.city}</li>
                                                    <li>{orderDetails?.billingAddress.state}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </div>
                </LayoutOne>
            </Fragment>
            {/* <Footer /> */}
        </>
    );
};

export default OrderDetails;
