const AppConsts = {
    apiBaseUrl: "https://api.bismifood.com/Ecommerce",
    // apiBaseUrl: "https://localhost:7050/Ecommerce",
    //appBaseUrl: "https://ecommerce.makinus.com",
    // apiBaseUrl: "https://api.bismifood.com/Ecommerce",
    //  apiBaseUrl: "https://localhost:7050/Ecommerce",
    // appBaseUrl: "https://ecommerce.makinus.com",
    notAvailable: "na",
    productImagePath: 'https://api.bismifood.com/Ecommerce/ProductImage/Product/%s/Image/%s/Company/%s',
    productVariantImagePath: 'https://api.bismifood.com/Ecommerce/Product/%s/Image/Variant/%s/Company/%s',
    categoryFilterTag: 'filter_category',
    filterTag: 'filter_tag',
    errorMsgStyle: {
      color: "red",
      fontSize: "14px",
    },
    DATE_FORMAT_DD_MM_YYYY_WITH_SLASH: "DD/MM/YYYY",
    DATE_FORMAT_MMMM_D_COMMA_YYYY_AT_H_MM_A: "MMMM D, YYYY [at] h:mm a",
    base64ImagePrefix: "data:image/jpg;base64,",
    productNameDisplayLimit: 60
  };
  export default AppConsts;
  