import cogoToast from 'cogo-toast';
import axios from 'axios';
import AppConsts from '../../appconst';
import axiosInstance from '../../auth/AxiosInstance';
import { getLoggedInUserId } from '../../apputils';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fulfillmentStatus = createAsyncThunk(
    "fulfillmentStatus",
    async (OrderId, thunkApi) => {
        try {
            const response = await axios.get(
                `${AppConsts.apiBaseUrl}/Fulfillment/GetAllFulfillmentListByOrderId/${OrderId}`,
                {
                    params: {
                      CompanyId: localStorage.getItem("companyId"),
                    },
                    headers: {
                      Accept: "application/json",
                    },
                });
            return response.data;

        } catch (error) {
            return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
        }
    }
);

const fulfillmentSlice = createSlice({
    name: "fullfillment",
    initialState: {
        fulfillmentList: [],
    },
    extraReducers: {
        [fulfillmentStatus.fulfilled]: (state, action) => {
            state.loading = false;
            state.fulfillmentList = action.payload;
        },  
    },
});

export const thunks = {
    fulfillmentStatus
};

export default fulfillmentSlice.reducer;
