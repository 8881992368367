import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '../../auth/AxiosInstance';
import cogoToast from 'cogo-toast';
import axios from 'axios';
import AppConsts from '../../appconst';
import { getLoggedInUserId } from '../../apputils';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const Plogin = createAsyncThunk(
  "Plogin",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Users/LoginUser`,
        {...data,companyId: localStorage.getItem("companyId") }, 
        {
          headers: {
            "Accept":"application/json",
          },
        });
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
export const MSlogin = createAsyncThunk(
  "MSlogin",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Users/LoginUser`,
        {...data,companyId: localStorage.getItem("companyId") }, 
        {
          headers: {
            "Accept":"application/json",
          },
        });
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
export const swapAlternateUserId = createAsyncThunk(
  "swapAlternateUserId",
  async (_, thunkApi) => {
    try {
      const response = await axios.put(
        `${AppConsts.apiBaseUrl}/Auth/SwapAlternateUserId/userId/${getLoggedInUserId()}/alterUserId/${localStorage.getItem(
          "alternateLoginId"
        )}/companyId/${localStorage.getItem("companyId")}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      return response.data;

    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

export const signUpUser = createAsyncThunk(
  "signUpUser",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Users/SignupUsers`,
        { ...data, companyId: localStorage.getItem("companyId") },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);


export const signUpUserPassword = createAsyncThunk(
  "signUpUserPassword",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Auth/Password/SignUp`,
        { ...data, companyId: localStorage.getItem("companyId") },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

export const LoginUserPassword = createAsyncThunk(
  "LoginUserPassword",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Auth/Password/Login`,
        { ...data, companyId: localStorage.getItem("companyId") },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);
export const changePassword = createAsyncThunk(
  "changePassword",
  async (password, thunkApi) => {
    try {
      const response = await axios.put(
        `${
          AppConsts.apiBaseUrl
        }/Users/ChangePassword/userId/${getLoggedInUserId()}/${password}/${localStorage.getItem("companyId")}`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);
export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (data, thunkApi) => {
    try {
      console.log('data.email', data.email);
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Auth/Verify/Otp?Email=${data.email}&Otp=${data.otp}&CompanyId=${Number(localStorage.getItem('companyId') || 0)}`);
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
export const updateOtp = createAsyncThunk(
  "updateOtp",
  async (data, thunkApi) => {
    try {
      const response = await axios.put(
        `${AppConsts.apiBaseUrl}/Users/Update/Otp/email/${data.email}`,
        {
          params: {
            companyId: localStorage.getItem("companyId"),
          },
          headers: {
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, thunkApi) => {
    try {
      const response = await axios.put(
        `${AppConsts.apiBaseUrl}/Users/ForgotPassword/email/${
          thunkApi.getState().auth?.userDetails?.email
        }/password/${data.password}/otp/${data.otp}`,
        {
          params: {
            companyId: localStorage.getItem("companyId"),
          },
          headers: {
            Accept: "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

export const companyLogo = createAsyncThunk(
  "companyLogo",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Companies/GetCompanyImageById`,
        {
          params: {
            CompanyId: localStorage.getItem("companyId"),
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);

export const getCompanyId = createAsyncThunk(
  "get-CompanyId",
  async (_, thunkApi) => {
    try {
      const hostName = window.location.hostname;
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Companies/GetCompanyId`,
        {
          params: {
            companyName: hostName,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error instanceof Error ? error.message : "Something went wrong"
      );
    }
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState: {
    Plogin: [],
    MSlogin: [],
    userDetails: {},
    companyLogoImage: {},
    signupSuccess: false,signupError:false,
    verLoginSuccess:false,verLoginError:false,
    PassLoginSuccess:false,passLoginError:false,
    MSloginSuccess:false,MSLoginError:false

  },
  extraReducers: {
    [verifyOtp.fulfilled]: (state, action) => {
      state.loading = false
      localStorage.setItem("accessToken",action.payload.accessToken);
      localStorage.setItem("refreshToken",action.payload.refreshToken);
      localStorage.setItem("userId", action.payload.userId); 
      const name = action.payload.userName;
      localStorage.setItem("userName", name);
       cogoToast.success(`Hi ${name}`, {position: "top-center"});
       state.verLoginSuccess = true;
    }, 
    [verifyOtp.rejected]: (state, action) => {
      state.loading = false
      cogoToast.error(action.payload.message, { position: "top-center" });
       state.verLoginError = true;
    },
    [Plogin.fulfilled]: (state, action) => {
      state.loading = false;
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem("userId", action.payload.userId);
      console.log(localStorage);
      cogoToast.success(action.payload.message, { position: "top-center" });
      state.PassLoginSuccess = true;
    },
    [Plogin.rejected]: (state, action) => {
      state.loading = false;
      cogoToast.error(action.payload.message, { position: "top-center" });
      state.passLoginError = true;

    },
    [MSlogin.fulfilled]: (state, action) => {
      state.loading = false;
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem("userId", action.payload.userId);
      console.log(localStorage);
      cogoToast.success(action.payload.message, { position: "top-center" });
      state.MSloginSuccess = true;
    },
    [MSlogin.rejected]: (state, action) => {
      state.loading = false;
      cogoToast.error(action.payload.message, { position: "top-center" });
      state.MSLoginError = true;

    },
    [signUpUser.rejected]: (state, action) => {
      state.loading = false;
      cogoToast.error(action.payload.message, { position: "top-center" });
      state.signupError = true;

    },
    [signUpUser.fulfilled]: (state, action) => {
      state.loading = false;
      console.log("signUpUser ", action.payload);
      if (action.payload.status === 200) {
        cogoToast.success(action.payload.message, {
          position: "top-center",
        });
        state.signupSuccess = true;
      } else {
        cogoToast.error(action.payload.message, { position: "top-center" });
      }
    },

    
    [signUpUserPassword.rejected]: (state, action) => {
      state.loading = false;
      cogoToast.error(action.payload.message, { position: "top-center" });
      state.signupError = true;

    },
    [signUpUserPassword.fulfilled]: (state, action) => {
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem("userId", action.payload.userId);
      const name = action.payload.userName;
      cogoToast.success(`Hi ${name}`, {position: "top-center"});
      state.PassLoginSuccess = true;
    },
    [LoginUserPassword.rejected]: (state, action) => {
      state.loading = false;
      cogoToast.error(action.payload.message, { position: "top-center" });
      state.signupError = true;

    },
    [LoginUserPassword.fulfilled]: (state, action) => {
      localStorage.setItem("accessToken", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      localStorage.setItem("userId", action.payload.userId);
      const name = action.payload.userName;
      cogoToast.success(`Hi ${name}`, {position: "top-center"});
      state.PassLoginSuccess = true;
    },
    
    [changePassword.fulfilled]: (state, action) => {
      cogoToast.success("Password changed successfully", {
        position: "top-center",
      });
    },
    [companyLogo.rejected]: (state, action) => {
      state.loading = false;
    },
    [companyLogo.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyLogoImage = action.payload;
    },
    [getCompanyId.fulfilled]: (state, action) => {
      state.loading = false;
      localStorage.setItem("companyId", action.payload);
    },
  },
  reducers: {
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    setSignupSuccess(state, action) {
      state.signupSuccess = action.payload;
    },
  },
});

export const thunks = {
  Plogin,
  MSlogin,
  signUpUser,
  swapAlternateUserId,
  changePassword,
  updateOtp,
  forgotPassword,
  verifyOtp
};
export const { setUserDetails, setSignupSuccess } = authSlice.actions;
export default authSlice.reducer;
