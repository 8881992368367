import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import AppConsts from "../../appconst";

export const footersList = createAsyncThunk('uiSetup/all-footers', async (_, thunkApi) => {
  try {
    const response = await axios.get(
      `${AppConsts.apiBaseUrl}/Footers/GetAllFootersList`,
      {
        params: {
          CompanyId: localStorage.getItem("companyId"),
        },
        headers: {
          Accept: "application/json",
        },
      },
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error instanceof Error ? error.message : 'Something went wrong');
  }
});


export const footerSectionListById = createAsyncThunk(
  "footersection-list",
  async (id, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/TermCondition/GetTermConditionDetailsById`,
        {
          params: {
            Id: id
          },
          headers: {
            Accept: 'application/json',
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);



export const footerSectionList = createAsyncThunk(
  "footersection-list",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/TermCondition/GetAllTermConditionList`,
        {
          params: {
            companyId: localStorage.getItem("companyId"),
          },
          headers: {
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const footersSlice = createSlice({
  name: "footers",
  initialState: {
    loading: false, // Added loading property
    footerLists: [],
    footerSectionLists: [],
    footerSectionListByIds: [],
  },
  extraReducers: {
    [footersList.fulfilled]: (state, action) => {
      state.loading = false;
      state.footerLists = action.payload;
    },
    [footersList.pending]: (state) => {
      state.loading = true;
    },
    [footersList.rejected]: (state) => {
      state.loading = false;
      // Handle rejection if needed
    },
    [footerSectionListById.fulfilled]: (state, action) => {
      state.loading = false;
      state.footerSectionListByIds = action.payload;
    },
    [footerSectionListById.pending]: (state) => {
      state.loading = true;
    },
    [footerSectionListById.rejected]: (state) => {
      state.loading = false;
      // Handle rejection if needed
    },
     [footerSectionList.pending]: (state) => {
      state.loading = true;
    },
    [footerSectionList.fulfilled]: (state, action) => {
      state.loading = false;
      state.footerSectionLists = action.payload;
    },
    [footerSectionList.rejected]: (state) => {
      state.loading = false;
      // Handle rejection if needed
    },
  },
});

export const thunks = {
  footersList, footerSectionList
};

export default footersSlice.reducer;
