// import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import AppConsts from '../../appconst';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const companiesDetails = createAsyncThunk(
    "companyDetails",
    async (_, thunkApi) => {
      try {
        const response = await axios.get(
          `${AppConsts.apiBaseUrl}/Companies/GetCompaniesDetailsById`,{
            params: 
            {
              id: localStorage.getItem('companyId')
            }
          }
        );
        console.log(response.data);
        return response.data;
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );
  const companiesSlice = createSlice({
    name: "companies",
    initialState: {
        companyDetails:null,
    },
    extraReducers: {
        [companiesDetails.rejected]: (state, action) => {
            state.loading = false
          },
          [companiesDetails.fulfilled]: (state, action) => {
            state.loading = false
            state.companyDetails = action.payload
          },
        },
        reducers: {
            setCompanyDetails(state, action){
                state.companyDetails = action.payload;
            }
        },
    });

export const { setCompanyDetails } = companiesSlice.actions;
export default companiesSlice.reducer;