import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  const { companyLogoImage } = useSelector(state => state.auth);
  const imageUrlLogo = `data:image/png;base64,${companyLogoImage.imageBase64}`;
  const { companyDetails } = useSelector((state) => state.companies);
  const { t } = useTranslation();
  
  return (
    <div className={clsx("copyright", spaceBottomClass, colorClass)}>
      <div className="footer-logo">
        <Link to={process.env.PUBLIC_URL + "/"}>
        <img
                src={imageUrlLogo}
                height="50"
                className="d-inline-block align-top"
                alt="Base64 Image"
                />
        </Link>
      </div>
      <div class="footer-text">
                <p>{companyDetails?.aboutUs}</p>
      </div>
       <br></br>
      <p>  
        &copy; {new Date().getFullYear()}{" "}
        <Link
          href="https://hasthemes.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Flone
        </Link>
        .<br /> {t("all_rights_reserved")}
      </p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string
};

export default FooterCopyright;
