import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import { companiesDetails } from "../../store/slices/companies-slice";
import { footersList, footerSectionList } from "../../store/slices/footers-slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu
}) => {
  const { companyDetails } = useSelector((state) => state.companies);
  const { companyLogoImage } = useSelector(state => state.auth);
  const imageUrlLogo = companyLogoImage ? `data:image/png;base64,${companyLogoImage.imageBase64}` : '';
  const { footerLists, footerSectionLists } = useSelector(state => state.footers);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

useEffect(() => {
  dispatch(companiesDetails());
}, []);

useEffect(() => {
  dispatch(footersList());
}, []);

useEffect(() => {
  const filter = footerLists.filter(item => item.isVisible === 'T');
  setFilterItems(filter);
}, [footerLists]);

useEffect(() => {
  dispatch(footerSectionList());
}, []);

useEffect(() => {
  if (footerSectionLists && footerSectionLists.length > 0) { 
      const filtered = footerSectionLists.filter(item => item.active === 'T');
      setFilteredItems(filtered);
  }
}, [footerSectionLists]);

const handleLinkClick = (value) => {
  window.open(value, "_blank");
};

  return (
    <footer className={clsx("footer-area", backgroundColorClass, spaceTopClass, spaceBottomClass, extraFooterClass, spaceLeftClass, spaceRightClass )}>
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-6 col-sm-4"
            }`}
          >
            {/* footer copyright */}
            <FooterCopyright
              footerLogo="/assets/img/logo/logo.png"
              spaceBottomClass="mb-30"
            />
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-3"
            }`}
          >
            <div className="footer-widget mb-30 ml-30">
              <div className="footer-title">
                <h3>{t("help_&_support")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                {filteredItems.map((footer, index) => (
                    <li key={index}>
                        <Link to={`/footer-detail/${footer.id}`}> 
                            {footer.tittle}
                        </Link>
                    </li>
                ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-3"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-50"
              }`}
            >
              <div className="footer-title">
                <h3>{t("useful_links")}</h3>
              </div>
              <div className="footer-list">
              <ul>
                {filterItems.map((footer, index) => (
                    <li key={index}>
                        <Link to="#" onClick={() => handleLinkClick(footer.proprietorLink)}>
                            {footer.proprietorName}
                        </Link>
                    </li>
                ))}
              </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-2"
            }`}
          >
            <div
              className={`${
                sideMenu
                  ? "footer-widget mb-30 ml-145"
                  : "footer-widget mb-30 ml-75"
              }`}
            >
              <div className="footer-title">
                <h3>{t("follow_us")}</h3>
              </div>
              <div className="footer-list">
                <ul>
                <li>
                      {companyDetails?.faceBook && (
                        <Link
                          onClick={() => {
                          handleLinkClick(companyDetails?.faceBook);
                          }}>
                          Facebook
                        </Link>
                      )}
                    </li>
                    <li>
                      {companyDetails?.twitter && (
                        <Link
                          onClick={() => {
                            handleLinkClick(companyDetails?.twitter);
                          }}>
                          Twitter
                        </Link>
                      )}
                    </li>
                    <li>
                      {companyDetails?.instagram && (
                        <Link
                          onClick={() => {
                            handleLinkClick(companyDetails?.instagram);
                          }}>
                          Instagram
                        </Link>
                      )}
                    </li>
                    <li>
                      {companyDetails?.whatsApp && (
                        <Link
                          onClick={() => {
                            handleLinkClick(companyDetails?.whatsApp);
                          }}>
                          Youtube
                        </Link>
                      )}
                    </li>
                    <li>
                      {companyDetails?.linkedIn && (
                        <Link
                          onClick={() => {
                            handleLinkClick(companyDetails?.linkedIn);
                          }}>
                          Linked In
                        </Link>
                      )}
                    </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div
            className={`${
              sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
            }`}
          >
            footer newsletter
            <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            />
          </div> */}
        </div>
      </div>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default FooterOne;
